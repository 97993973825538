import {makeStyles} from '@material-ui/core/styles';

import {CremaTheme} from '../../../types/AppContextPropsType';

export const styles = makeStyles((theme: CremaTheme) => ({
  card: {
    maxWidth: 576,
    width: '100%',
    textAlign: 'center',
    padding: 24,
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up('sm')]: {
      padding: 40,
    },
    [theme.breakpoints.up('md')]: {
      padding: 48,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 48,
      paddingRight: 48,
    },
    '&:before': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      width: 130,
      height: 9,
      borderBottomRightRadius: 80,
      borderBottomLeftRadius: 80,
      marginRight: 'auto',
      marginLeft: 'auto',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
