/* eslint-disable no-template-curly-in-string */
import axios from 'axios';
import {RatingData} from 'modules/molecules';
import {FormLocation} from 'modules/organisms';
import {generalStatuses, loadStatuses} from 'shared/constants/AppConst';
import {dateHelper} from '../shared/helpers';

require('dotenv').config();

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

//#region AUTH
export const setAuthToken = (token: string | null) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Token'];
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }
};
const refreshAccessToken = async () => {
  try {
    const res = await jwtAxios.post('refresh-token', {
      refreshToken: localStorage.getItem('refreshToken'),
    });
    return res.data.accessToken;
  } catch (err) {
    return null;
  }
};
const signin = async (body: {email: string; password: string}) => {
  try {
    const res = await jwtAxios.post('login', body);
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const signup = async (data: any, type: string) => {
  try {
    let formData = new FormData();

    // Add user info
    formData.append('email', data['email']);
    formData.append('password', data['password']);
    formData.append('username', data['username']);
    const userDetails = {name: data['name'], phone: data['phone'].toString()};
    formData.append('userDetails', JSON.stringify(userDetails));

    // Add organization info
    const organizationContactInfo = {
      address: data['companyAddress'],
      person: data['companyContactName'],
      email: data['companyEmail'],
      phone: data['companyPhone'],
    };
    const organization = {
      name: data['companyName'],
      organizationContactInfo: organizationContactInfo,
    };
    formData.append('organization', JSON.stringify(organization));

    if (type === 'carrier') {
      // Add carrier info
      formData.append('mcNumber', data['mcNumber']);
      formData.append('dotNumber', data['dotNumber']);
      formData.append('document', data['firstFile'], data['firstFile']['name']);
      formData.append(
        'document',
        data['secondFile'],
        data['secondFile']['name'],
      );
      formData.append('document', data['thirdFile'], data['thirdFile']['name']);
      if (data['trucks']) {
        const trucks = data['trucks'].map((item: any) => {
          return {type: item['typeId'], count: item['num']};
        });
        formData.append('trucks', JSON.stringify(trucks));
      }
      if (data['trailers']) {
        const trailers = data['trailers'].map((item: any) => {
          return {type: item['typeId'], count: item['num']};
        });
        formData.append('trailers', JSON.stringify(trailers));
      }
      if (data['driverTeams']) {
        const driverTeams = data['driverTeams'].map((item: any) => {
          return {type: item['typeId'], count: item['num']};
        });
        formData.append('driverTeams', JSON.stringify(driverTeams));
      }

      await jwtAxios.post('register/carrier', formData, {
        headers: {'content-type': 'multipart/form-data'},
      });
    } else {
      formData.append(
        'insuredAmount',
        data['insuredAmount'] === 1 ? '< $100,000.00' : '> $100,000.00',
      );

      await jwtAxios.post('register/shipper', formData, {
        headers: {'content-type': 'multipart/form-data'},
      });
    }
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const forgetPassword = async (email: string) => {
  try {
    const res = await jwtAxios.post('users/forgot-password', {email: email});
    return res;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const newPassword = async (code: string, password: string) => {
  try {
    await jwtAxios.post('users/reset-password', {
      code: code,
      newPassword: password,
    });
    return 'done';
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
//#endregion

//#region TABLE
const tableList = async (url: string, data: any) => {
  try {
    var dataConverted = {
      ...data,
      columns: JSON.stringify(data.columns),
      filters: JSON.stringify(
        data.filters
          .filter(
            (f: any) => f.value !== '0' && f.value !== 0 && f.value !== '',
          )
          .map((f: any) => {
            if (typeof f.value === 'object' && f.value !== null) {
              return {name: f.name, value: f.value};
            } else {
              return {name: f.name, value: ['' + f.value]};
            }
          }),
      ),
      order: {
        column: data.order.column,
        direction: data.order.direction === 'asc' ? 'ASC' : 'DESC',
      },
      page: data.page + 1,
    };

    /*var data2 = {"columns":["users.username","status","users.createdAt","role"],
        "filters":[{"name":"status","value":1}],
        "order":{"column":"users.createdAt","direction":"DESC"},"page":1,"pageSize":10};*/

    const res = await jwtAxios.get(url, {
      params: dataConverted,
    });

    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
//#endregion

//#region LOADS
const loadCreate = async (data: any, locations: FormLocation[]) => {
  try {
    let customFromData: any = {
      pickupTimestampStart: dateHelper.convertToServerFormat(
        data['pickupDatetimeStart'],
      ),
      dropOffTimestampStart: dateHelper.convertToServerFormat(
        data['dropoffDatetimeStart'],
      ),
      loadTypeId: data['loadType'],
      rate: 1,
      rateTypeId: 1,
      carriersSortingTypeId: data['carrierSortingTypes'],
      expiration: dateHelper.convertToServerFormat(
        data['offerExpirationDatetime'],
      ),
      loadAccessories: data['loadAccessoriesSelect'],
      loadEquipment: data['loadEquipmentSelect'],
      description: data['description'],
      weight: data['weight'],
      commodity: data['commodity'],
      refNumber: data['refNumber'],
    };
    if (data['addPickupDatetimeRange']) {
      customFromData['pickupTimestampEnd'] = dateHelper.convertToServerFormat(
        data['pickupTimestampEnd'],
      );
    }
    if (data['addDropoffDatetimeRange']) {
      customFromData['dropOffTimestampEnd'] = dateHelper.convertToServerFormat(
        data['dropOffTimestampEnd'],
      );
    }
    let localLocs = [];
    for (const loc of locations) {
      const newLoc = {
        address: data['googlePlaceName' + loc.id],
        name: data['locationName' + loc.id],
        latitude: data['googlePlaceLat' + loc.id],
        longitude: data['googlePlaceLng' + loc.id],
        locationType: data['locationType' + loc.id],
        contactName: data['contactName' + loc.id],
        contactPhone: data['contactPhone' + loc.id],
        number: data['number' + loc.id],
        saveLocation: data['saveLocation' + loc.id] || false,
        savedLocationId: data['savedLocationId' + +loc.id],
      };
      localLocs.push(newLoc);
    }
    customFromData['locations'] = localLocs;
    await jwtAxios.post('loads', customFromData);
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};

const loadGet = async (loadId: string) => {
  try {
    let res: any;
    res = await jwtAxios.get(`loads/${loadId}`);
    return res.data;
  } catch (e) {
    throw new Error('Something went wrong!');
  }
};

const loadEdit = async (data: any, locations: any, loadId: string) => {
  try {
    let customFromData: any = {
      description: data['description'],
      commodity: data['commodity'],
      refNumber: data['refNumber'],
      weight: Number(data['weight']),
      loadId: Number(loadId),
      pickupTimestampStart: dateHelper.convertToServerFormat(
        data['pickupDatetimeStart'],
      ),
      dropOffTimestampStart: dateHelper.convertToServerFormat(
        data['dropoffDatetimeStart'],
      ),
      loadTypeId: data['loadType'],
      rate: 1,
      rateTypeId: 1,
      carriersSortingTypeId: data['carrierSortingTypes'],
      expiration: dateHelper.convertToServerFormat(
        data['offerExpirationDatetime'],
      ),
      loadAccessories: data['loadAccessoriesSelect'],
      loadEquipment: data['loadEquipmentSelect'],
    };
    if (data['addPickupDatetimeRange']) {
      customFromData['pickupTimestampEnd'] = dateHelper.convertToServerFormat(
        data['pickupTimestampEnd'],
      );
    }
    if (data['addDropoffDatetimeRange']) {
      customFromData['dropOffTimestampEnd'] = dateHelper.convertToServerFormat(
        data['dropOffTimestampEnd'],
      );
    }
    let localLocs = [];
    for (const loc of locations) {
      const newLoc = {
        id: loc.id,
        address: data['googlePlaceName' + loc.id],
        name: data['locationName' + loc.id],
        latitude: data['googlePlaceLat' + loc.id],
        longitude: data['googlePlaceLng' + loc.id],
        locationType: data['locationType' + loc.id],
        contactName: data['contactName' + loc.id],
        contactPhone: data['contactPhone' + loc.id],
        number: data['number' + loc.id],
        saveLocation: data['saveLocation' + loc.id] || false,
        savedLocationId: data['savedLocationId' + +loc.id],
      };
      localLocs.push(newLoc);
    }
    customFromData['locations'] = localLocs;
    const res = await jwtAxios.put('loads', customFromData);
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};

const loadExpirationEdit = async (loadId: number, date: string) => {
  try {
    const data = {loadId: +loadId, newExpiration: date};
    const res = await jwtAxios.put('loads', data);
    return res;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const loadBatchUpload = async (file: File) => {
  try {
    let formData = new FormData();
    formData.append('csv_loads_file', file, file['name']);

    const res = await jwtAxios.post('loads/batchImport', formData, {
      headers: {'content-type': 'multipart/form-data'},
    });
    return res;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const loadCancel = async (id: string, message: string) => {
  try {
    console.log('loadCancel - status', {
      status: loadStatuses.filter((value) => value.name === 'Canceled')[0],
    });
    const data = {
      status: loadStatuses.filter((value) => value.name === 'Canceled')[0].id,
      message: message,
    };
    const res = await jwtAxios.put('loads/' + id + '/status', data);
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
//#endregion

//#region USERS
const activateUser = async (userId: any) => {
  try {
    const res = await jwtAxios.get('users/activate/' + userId);
    return res;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const blockUser = async (userId: any) => {
  try {
    const res = await jwtAxios.get('users/block/' + userId);
    return res;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const getUserRatings = async (userRole: string) => {
  try {
    let res = await jwtAxios.get('tours/rating/' + userRole);
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const setUserRatings = async (tourId: string, ratings: RatingData[]) => {
  try {
    let rats = [];
    for (const rat of ratings) {
      const newRat = {
        criteria: rat.id,
        rating: rat.value,
      };
      rats.push(newRat);
    }

    let res = await jwtAxios.post('tours/' + tourId + '/rate/', {
      ratings: rats,
    });
    return res.data;
    //return 'done';
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const accountGet = async (id: string) => {
  try {
    let res = await jwtAxios.get('profile' + (id === '' ? '' : '/' + id));
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const accountUserChange = async (data: any) => {
  try {
    let customFromData: any = {
      email: data.email,
      username: data.username,
      name: data.name,
      phone: data.phone,
    };
    if (data.oldPassword !== '') {
      customFromData['oldPassword'] = data.oldPassword;
      customFromData['newPassword'] = data.newPassword;
    }

    const res = await jwtAxios.put('users', customFromData);
    return res;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const accountOrganisationChange = async (data: any) => {
  try {
    let customFromData: any = {
      address: data.companyAddress,
      person: data.companyContactName,
      email: data.companyEmail,
      name: data.companyName,
      phone: data.companyPhone,
    };

    const res = await jwtAxios.put('users/organization', customFromData);
    return res;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const accountCarrierChange = async (data: any) => {
  try {
    let formData = new FormData();
    formData.append('mcNumber', data['mcNumber']);
    formData.append('dotNumber', data['dotNumber']);
    /*if (data['firstFile']) {
            formData.append('document', data['firstFile'], data['firstFile']['name']);
        } else {
            formData.append('document', "");
        }
        if (data['secondFile']) {
            formData.append('document', data['secondFile'], data['secondFile']['name']);
        } else {
            formData.append('document', "");
        }
        if (data['thirdFile']) {
            formData.append('document', data['thirdFile'], data['thirdFile']['name']);
        } else {
            formData.append('document', "");
        }*/
    if (data['trucks']) {
      const trucks = data['trucks'].map((item: any) => {
        return {type: item['typeId'], count: item['num']};
      });
      formData.append('trucks', JSON.stringify(trucks));
    }
    if (data['trailers']) {
      const trailers = data['trailers'].map((item: any) => {
        return {type: item['typeId'], count: item['num']};
      });
      formData.append('trailers', JSON.stringify(trailers));
    }
    if (data['driverTeams']) {
      const driverTeams = data['driverTeams'].map((item: any) => {
        return {type: item['typeId'], count: item['num']};
      });
      formData.append('driverTeams', JSON.stringify(driverTeams));
    }
    for (const pair of formData.entries()) {
    }
    const res = await jwtAxios.post('users/carrier', formData, {
      headers: {'content-type': 'multipart/form-data'},
    });
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const accountShipperChange = async (data: any) => {
  try {
    let formData = new FormData();
    formData.append('insuredAmount', data['insuredAmount']);
    if (data['signatureFile']) {
      formData.append(
        'signature',
        data['signatureFile'],
        data['signatureFile']['name'],
      );
    }

    const res = await jwtAxios.post('users/shipper', formData, {
      headers: {'content-type': 'multipart/form-data'},
    });
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const organizationsGet = async () => {
  try {
    let res = await jwtAxios.get('users/organization');
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const creatableRolesGet = async () => {
  try {
    let res = await jwtAxios.get('users/roles/creatable');
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const filterableRolesGet = async () => {
  try {
    let res = await jwtAxios.get('users/roles/filterable');
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};

const organizationCreate = async (data: any) => {
  let formData = new FormData();

  // Add organization info

  const organizationContactInfo = {
    address: data['address'],
    person: data['person'],
    email: data['email'],
    phone: data['phone'],
  };

  const organizationData: any = {
    name: data['name'],
    type: data['type'] === 1 ? 'Shipper' : 'Carrier',
    organizationContactInfo: organizationContactInfo,
  };

  formData.append('organization', JSON.stringify(organizationData));

  if (organizationData.type === 'Carrier') {
    // Add carrier info
    formData.append('eldProvider', JSON.stringify(`${data['ELDProvider']}`));
    formData.append('mcNumber', JSON.stringify(`${data['MCNumber']}`));
    formData.append('dotNumber', JSON.stringify(`${data['DOTNumber']}`));
    formData.append('document', data['firstFile'], data['firstFile']['name']);
    formData.append('document', data['secondFile'], data['secondFile']['name']);
    formData.append('document', data['thirdFile'], data['thirdFile']['name']);
    formData.append('document', data['fourthFile'], data['fourthFile']['name']);
    if (data['trucks']) {
      const trucks = data['trucks'].map((item: any) => {
        return {
          type: item['typeId'],
          count: item['num'],
        };
      });
      formData.append('trucks', JSON.stringify(trucks));
    }
    if (data['trailers']) {
      const trailers = data['trailers'].map((item: any) => {
        return {type: item['typeId'], count: item['num']};
      });
      formData.append('trailers', JSON.stringify(trailers));
    }
    if (data['driverTeams']) {
      const driverTeams = data['driverTeams'].map((item: any) => {
        return {type: item['typeId'], count: item['num']};
      });
      formData.append('driverTeams', JSON.stringify(driverTeams));
    }
  } else {
    formData.append(
      'insuredAmount',
      data['insuredAmount'] === 1
        ? JSON.stringify('< $100,000.00')
        : JSON.stringify('> $100,000.00'),
    );
  }

  try {
    await jwtAxios.post('/users/organization', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};

const createUser = async (
  data: any,
  roleId: number,
  organizationId: number,
) => {
  try {
    let customFromData: any = {
      email: data.email,
      username: data.username,
      userDetails: {
        name: data.name,
        phone: data.phone,
      },
      password: data.password,
      roleId: roleId,
    };
    if (organizationId !== -1) {
      customFromData['organizationId'] = organizationId;
    }
    const res = await jwtAxios.post('users', customFromData);
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const updateUser = async (data: any, userId: string) => {
  try {
    let customFromData: any = {
      email: data.email,
      name: data.name,
      phone: data.phone,
    };
    if (data.password !== '') {
      customFromData['newPassword'] = data.password;
    }
    const res = await jwtAxios.put('users/' + userId, customFromData);
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
//#endregion

//#region OFFERS
const getOffer = async (loadId: string, proposalId: string) => {
  try {
    let res: any;
    if (proposalId === '') {
      res = await jwtAxios.get('proposal/' + loadId);
    } else {
      res = await jwtAxios.get(
        'proposal/' + loadId + '/proposal/' + proposalId,
      );
    }
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const offerCreate = async (
  loadId: string,
  price: string,
  message: string,
  aiEldTruckName: string | null,
  bidId: string | null,
) => {
  try {
    console.log('offerCreate/offerUpdate');
    const res = await jwtAxios.post('proposal/' + loadId, {
      price: +price,
      message: message,
      eldTruckName: aiEldTruckName,
    });

    // if (aiEldTruckName && bidId) {
    //   const resAiEld = await jwtAxios.put('bid/' + bidId, {
    //     eldTruckName: aiEldTruckName,
    //   });
    // }
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const offerAccept = async (proposalId: string) => {
  try {
    console.log('offerAccept - status', {
      status: generalStatuses.filter((value) => value.name === 'Accepted')[0],
    });
    const res = await jwtAxios.put('proposal/' + proposalId, {
      status: generalStatuses.filter((value) => value.name === 'Accepted')[0]
        .id,
    });
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const contractUpload = async (file: File, loadId: string) => {
  try {
    let formData = new FormData();
    formData.append('status', '14');
    formData.append('contract', file, file['name']);

    const res = await jwtAxios.post('proposal/load/' + loadId, formData, {
      headers: {'content-type': 'multipart/form-data'},
    });

    return res;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const offerCancel = async (isCarrier: boolean, id: string, message: string) => {
  try {
    console.log('offerCancel - status', {
      status: generalStatuses.filter((value) => value.name === 'Canceled')[0],
    });
    if (isCarrier) {
      const data = {
        status: generalStatuses.filter((value) => value.name === 'Canceled')[0]
          .id,
        message: message,
      };
      const res = await jwtAxios.post('proposal/load/' + id, data);
      return res.data;
    } else {
      const data = {
        status: generalStatuses.filter((value) => value.name === 'Canceled')[0]
          .id,
        message: message,
      };
      const res = await jwtAxios.put('proposal/' + id, data);
      return res.data;
    }
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
//#endregion

//#region TOURS
const tourGetTrack = async (tourId: any) => {
  try {
    let res: any;
    res = await jwtAxios.get('tours/track/' + tourId);
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const tourGetAssignees = async () => {
  try {
    let res: any;
    res = await jwtAxios.get('tours/assignees');
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const tourAssign = async (tourId: any, driverId: any) => {
  try {
    let res: any;
    res = await jwtAxios.post('tours/' + tourId + '/assign/' + driverId);
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};
const tourChangeStatus = async (tourId: any, statusId: number) => {
  try {
    const data = {
      status: statusId,
      message: '',
    };
    const res = await jwtAxios.put('tours/' + tourId + '/status', data);
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};

const savedLocationsGet = async () => {
  try {
    const res = await jwtAxios.get('/loads/list/getSavedLocations');
    return res;
  } catch (err) {
    console.log(err);
  }
};

const deleteDriver = async () => {
  try {
    jwtAxios.delete('/users/deleteDriver');
  } catch (error) {
    console.log(error);
  }
};

//#endregion

//#region AIELD

const getTrucksWithAiEld = async (loadId: string) => {
  try {
    const res = await jwtAxios.get(`/loads/${loadId}/proposals/map`);
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};

const aiEldTruckGet = async (
  id: number,
  eldTruckName: string,
  carrierEmail: string,
) => {
  try {
    const res = await jwtAxios.get(
      `services/${id}/trucks/${eldTruckName}/${carrierEmail}`,
    );
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};

const aiEldServicesGet = async (serviceId: number) => {
  try {
    const res = await jwtAxios.get(`/services/${serviceId}/trucks`);
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};

const installAiEld = async (
  clientId: string,
  clientSecret: string,
  serviceId: number,
) => {
  try {
    const res = await jwtAxios.post('/organization-services', {
      serviceId: serviceId,
      clientId: clientId,
      clientSecret: clientSecret,
    });
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};

const updateAiEld = async (
  clientId: string,
  clientSecret: string,
  serviceId: number,
) => {
  try {
    const res = await jwtAxios.put('/organization-services', {
      organizationServiceId: serviceId,
      clientId: clientId,
      clientSecret: clientSecret,
    });
    return res.data;
  } catch (err: any) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error('Something went wrong!');
    }
  }
};

export const axiosService = {
  jwtAxios,

  // AUTH
  refreshAccessToken,
  signin,
  signup,
  forgetPassword,
  newPassword,

  // TABLE
  tableList,

  // LOADS
  loadGet,
  loadCreate,
  loadEdit,
  loadExpirationEdit,
  loadBatchUpload,
  loadCancel,

  // USERS
  activateUser,
  blockUser,
  getUserRatings,
  setUserRatings,
  accountGet,
  accountUserChange,
  accountOrganisationChange,
  accountCarrierChange,
  accountShipperChange,
  organizationsGet,
  creatableRolesGet,
  filterableRolesGet,
  createUser,
  updateUser,
  organizationCreate,
  savedLocationsGet,
  deleteDriver,

  // OFFERS
  getOffer,
  offerCreate,
  offerAccept,
  contractUpload,
  offerCancel,

  // TOURS
  tourGetTrack,
  tourGetAssignees,
  tourAssign,
  tourChangeStatus,

  // INTEGRATIONS
  installAiEld,
  updateAiEld,
  aiEldServicesGet,
  aiEldTruckGet,
  getTrucksWithAiEld,
};
