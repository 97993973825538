import {GoogleMap, InfoWindow, Marker} from '@react-google-maps/api';
import React, {useEffect, useState} from 'react';
import ReactGoogleMapsLoader from 'react-google-maps-loader';

import currentLocationIcon from './currentLocation.png';
import {MapMarker, MapWithMarkersProps} from './map-with-markers.types';
import specialMarkerIcon from './specialMarker.png';

export const MapWithMarkers: React.FC<MapWithMarkersProps> = (props) => {
  const {
    customZoom,
    markers,
    showInfo,
    mapHeight,
    polyline,
    currentLocation,
    onSpecialMarkerClick,
    icon,
  } = props;

  const [center, setCenter] = useState({
    lat: 44.5,
    lng: -89.5,
  });
  const [showInfoIndex, setShowInfoIndex] = useState(-1);
  const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState<number>(-1);

  const renderPolyline = (map: google.maps.Map) => {
    if (polyline !== null && polyline !== undefined && polyline.length > 0) {
      let flightPath = new window.google.maps.Polyline({
        path: polyline,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      flightPath.setMap(map);
    }
  };
  const markerAddress = (marker: MapMarker) => {
    console.log('markeeeer', marker);
    if (marker.type === 'truck') {
      return `Truck ELD number: ${marker.name} ${
        marker.additionalInfo ? `, ${marker.additionalInfo}` : ''
      } ${marker.price ? `Price: ${marker.price} $` : ''}`;
    }
    return `${marker.address}`;
  };

  useEffect(() => {
    if (markers.length > 0) {
      setCenter({lat: +markers[0].latitude, lng: +markers[0].longitude});
    }
  }, [markers]);

  useEffect(() => {
    if (props.center !== null && props.center !== undefined) {
      setCenter(props.center);
    }
  }, [props.center]);

  console.log('markeeeeers', markers);
  return (
    <ReactGoogleMapsLoader
      params={{
        key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
        libraries: 'places,geocode',
      }}
      render={(googleMaps) => {
        const map = googleMaps && (
          <GoogleMap
            mapContainerStyle={{width: '100%', height: mapHeight}}
            center={center}
            onLoad={(map) => {
              if (customZoom) {
                return;
              }
              let bounds = new window.google.maps.LatLngBounds();
              markers.forEach((marker) => {
                bounds.extend({lat: +marker.latitude, lng: +marker.longitude});
              });
              map.fitBounds(bounds);
              renderPolyline(map);
            }}
            zoom={customZoom ? 10 : 1}>
            {markers.map((marker, index) => {
              if (
                marker.latitude === undefined ||
                marker.longitude === undefined
              ) {
                return null;
              }

              if (marker.type === 'specialMarker') {
                return (
                  <Marker
                    onClick={() => {
                      console.log('clicked marker', marker);
                      if (onSpecialMarkerClick) {
                        onSpecialMarkerClick(marker);
                      }
                    }}
                    onMouseOver={() => setHoveredMarkerIndex(index)}
                    onMouseOut={() => setHoveredMarkerIndex(-1)}
                    key={'markerLocation'}
                    position={{
                      lat: +marker.latitude,
                      lng: +marker.longitude,
                    }}
                    icon={specialMarkerIcon}>
                    {hoveredMarkerIndex === index && (
                      <InfoWindow
                        onCloseClick={() => setHoveredMarkerIndex(-1)}>
                        <div style={{color: 'black'}}>
                          <div>{markerAddress(marker)}</div>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              }
              return (
                <Marker
                  icon={icon ? icon : ''}
                  key={'marker' + index}
                  position={{lat: +marker.latitude, lng: +marker.longitude}}
                  onMouseOver={() => setHoveredMarkerIndex(index)}
                  onMouseOut={() => setHoveredMarkerIndex(-1)}
                  onClick={() => {
                    console.log('clicked marker', marker);
                    if (onSpecialMarkerClick) {
                      onSpecialMarkerClick(marker);
                    } else if (showInfo) {
                      setShowInfoIndex(index);
                    }
                  }}>
                  {(hoveredMarkerIndex === index ||
                    showInfoIndex === index) && (
                    <InfoWindow
                      onCloseClick={() => {
                        setShowInfoIndex(-1);
                        setHoveredMarkerIndex(-1);
                      }}>
                      <div style={{color: 'black'}}>
                        <div>{markerAddress(marker)} </div>
                        {marker.contactDetails && (
                          <>
                            <div>{'------------'}</div>
                            <div>{marker.contactDetails.name}</div>
                            <div>
                              {'Name: ' + marker.contactDetails.contactName}
                            </div>
                            <div>
                              {'Phone: ' + marker.contactDetails.contactPhone}
                            </div>
                            <div>{'Note: ' + marker.contactDetails.notes}</div>
                          </>
                        )}
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              );
            })}
            {currentLocation && (
              <Marker
                key={'markerLocation'}
                position={{
                  lat: +currentLocation.lat,
                  lng: +currentLocation.lng,
                }}
                icon={currentLocationIcon}
              />
            )}
          </GoogleMap>
        );
        return map;
      }}
    />
  );
};
